@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
.container {
  max-width: 1690px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}
.CustomCheckBox input:checked ~ label::before {
  background-color: #317a9a;
  border-color: #317a9a;
}
.CustomCheckBox input:checked ~ label::after {
  transform: rotate(-45deg);
}
.progress {
  border-radius: 8px;
  height: 7px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}
.progress::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: #bf5547;
}

.blur {
  filter: blur(4px);
  transition: filter 0.3s;
}

.tooltip {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
}

.btnLoading div div svg {
  height: 24px;
}

/* .chart-container svg {
    width: 100%;
}
.chart-container svg > rect {
  width: 500px !important;
} */


.custom-range-slider {
  -webkit-appearance: none;
}

.custom-range-slider:focus {
  outline: none;
}

/* WebKit (Chrome, Safari) slider thumb */
.custom-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #bf5547; 
  border: 2px solid #E5E7EB; 
  cursor: pointer;
}

/* Firefox slider thumb */
.custom-range-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #bf5547; 
  border: 2px solid #E5E7EB; 
  cursor: pointer;
}